@keyframes fadein{
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}
@keyframes slide-in-top{
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes slide-in-left{
  from {
      transform: translateX(-100px);
    }
    to {
      transform: translateX(0px);
    }
}
@keyframes slide-in-right{
  from {
      transform: translateX(500px);
    }
    to {
      transform: translateX(0px);
    }
}
@keyframes rotate-right{
    from {
      transform: rotate(0deg);
      }
    to {
      transform: rotate(360deg);
      }
}
@keyframes rotate-left{
  from {
    transform: rotate(360deg);
    }
  to {
    transform: rotate(0deg);
    }
}
@keyframes expand{
  from {
    transform: scale(0.5,0.5);
    }
  to {
    transform: scale(1,1);
    }
}
@keyframes shrink{
  from {
    transform: scale(1.5,1.5); 
    transform: translateY(100px);
    }
  to {
    transform: scale(1,1);
    transform: translateY(0px);
    }
}
