.slidein-top.reveal{
    transform: translateY(-25rem);
}
.slidein-top.reveal.active{
    animation:slidein-top 1 2s linear;
    transform: translateY(0px);
}
.fadein1s.reveal{
    opacity: 0;
}
.fadein1s.reveal.active{
    animation:fadein 1 1s linear;
    opacity: 1;
}
.fadein1nh.reveal{
    opacity: 0;
}
.fadein1nh.reveal.active{
    animation:fadein 1 1.5s linear;
    opacity: 1;
}
.fadein.reveal{
    opacity: 0;
}
.fadein.reveal.active{
    animation:fadein 1 2s linear;
    opacity: 1;
}
.fadein-2sdelay.reveal{
    opacity: 0;
}
.fadein-2sdelay.reveal.active{
    animation:fadein-2sdelay 1 4s ease-in;
    opacity: 1;
}
.fadein-4sdelay.reveal{
    opacity: 0;
}
.fadein-4sdelay.reveal.active{
    animation:fadein-4sdelay 1 6s ease-in;
    opacity: 1;
}
.sliderightdisco.reveal{
    opacity: 0;
    transform: translateY(-31.25rem);
}
.sliderightdisco.reveal.active{
    opacity: 1;
    animation:sliderightdisco 1 2s linear;
    transform: translateY(0px);
}
.slideleftdisco.reveal{
    opacity: 0;
    transform: translateX(31.25rem);
}
.slideleftdisco.reveal.active{
    opacity: 1;
    animation:slideleftdisco 1 2s linear;
    transform: translateX(0px);
}
.sliderightdisco-2sdelay.reveal{
    opacity: 0;
    transform: translateY(-31.25rem);
}
.sliderightdisco-2sdelay.reveal.active{
    opacity: 1;
    animation:sliderightdisco-2sdelay 1 4s linear;
    transform: translateY(0px);
}
.sliderightdisco-4sdelay.reveal{
    opacity: 0;
    transform: translateY(-31.25rem);
}
.sliderightdisco-4sdelay.reveal.active{
    opacity: 1;
    animation:sliderightdisco-4sdelay 1 6s linear;
    transform: translateY(0px);
}


.expandcar.reveal{
    opacity: 0;
    transform: scale(0.01,0.01);
}
.expandcar.reveal.active{
    opacity: 1;
    animation:expandcar 1 2s linear;
    transform: scale(1,1);
}
.expandcarmob.reveal{
    opacity: 0;
    transform: scale(0.01,0.01);
}
.expandcarmob.reveal.active{
    opacity: 1;
    animation:expandcar 1 1s linear;
    transform: scale(1,1);
}