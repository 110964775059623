@import "./breakpoints.scss";
.amenties-wrapper{
    display: none!important;
}
.service-line-grid hr{
    margin: 0;
}
.expertise-wrap>div {
    filter: brightness(.9);
}
.AnIcon-img{
    transform: translateY(-69px);
}
.footer-wrap{
    a{
       &:hover, &:active,&:focus-within,&:focus{
            color: #fff;
        }
    }
}
.form-btn-wrap {
    padding: 0!important;
    border: none;

    input {
        padding: .78rem;
        background: linear-gradient(#DFBD69, #B88746);

        border-radius: .5rem;
        transition: all .3s ease-in-out;

        &:hover {
            background: linear-gradient(#B88746, #DFBD69, );
            transition: all .3s ease-in-out;
        }
    }
    @include breakpoint(upto-mobile){
        margin-left: 0!important;
    }
}
.enquiry-wrapper {
    .form-btn-wrap {
        padding: 0;
        border: none;

        input {
            padding: .78rem;
            background: linear-gradient(#DFBD69, #B88746);

            border-radius: .5rem;
            transition: all .3s ease-in-out;

            &:hover {
                background: linear-gradient(#B88746, #DFBD69, );
                transition: all .3s ease-in-out;
            }
        }
    }
}

@include breakpoint(upto-mobile) {
    .terms-wrapper{
        text-align: center;
    }
    .about-mob-wrapper{
        background: url("../assets/image/homeAbout.png") no-repeat;
        background-size: cover;
        width: 100%;
        height: max-content;
    }
 .arete-total-income {
    background: url("../assets/image/bg-box.jpg");
 }
    .css-ntnpet:focus-visible, .css-ntnpet[data-focus-visible] ,.chakra-select:focus-visible{
        z-index: 1;
        border-color: #3182ce00!important;
        box-shadow: 0 0 0 1px #3182ce00!important;
    }

 .invest-advantage-wrap{
    .plus{
        position: relative;
        &::before{
            content: "+";
            position: absolute;
            font-size: 3em;
            top: 27px;
            left: 27%;
            color: #d4ad4b;
        }
    }
 }
    .service-line-grid{
        h2{
            padding: 0;
        }
            hr{
                margin: 0!important; 
            } 
    }
    .arrow-wrapper{
        .fadein,ul {
            padding: 0 ;
        }
        .revealOnce {
            padding: 0;
            hr{
                margin: 0!important; 
            }
        }  
    }
    .amenties-wrapper{
        display: block!important;
    }
    .hompage_banner {
        video {
            height: 100vh;
            object-fit: cover;
            max-width: 150%;
            display: block;
            transform:translateX(0px) scale(1.029);
            border: 2px solid #fff;
            outline: none !important;

            &:focus,
            &:active,
            &:visited {
                outline: none !important;
            }
        }
    }

    .social-wrap {
        a {
            svg {
                width: 45px;
                height: 45px;
            }
        }
    }

    .blog-community-wrap {
        position: relative !important;
    }

    .slide-arrow-btn {
        display: grid;
        align-items: center;
        grid-template-columns: max-content 1fr;

        a {
            width: max-content;
        }

        button {
            width: max-content;
            display: block;
            margin: 0 0 0 auto;
            position: inherit;
        }
    }

    .ARETE-btn-wrap {
        .active_btn {
            filter: drop-shadow(2px 4px 6px #999);
        }
    }

    .Arete-content-wrap {
        >div {
            width: 96%;
            box-shadow: 0px 4px 8px 1px #999999b8;
        }
    }
    .service-wrap{
        background: #fff!important; 
        position: relative;
        padding-bottom: 2rem;
        .smart-head {
            transform: translateY(-18px) !important;
            background: #fff; 
            width: 100%;
            padding: 0 !important;
            z-index: 1; 
            p{
                width: 100%;
                font-size: 1.5em; 
                padding-bottom: 0;
                padding-top: 3rem; 
            }
        }
        .grid-line-box {
            grid-gap: 2.5rem;
            hr{
                display: none;
            }
            h2{
                font-size: .875em;
            }
            .circle-wrap{
                z-index: 1;
            }
        }
        &:before {
            content: '';
            width: 2px;
            height: 300px;
            background: #dfbd69;
            position: absolute;
            left: 36px;
            top: 5rem;
            z-index: 0;
            @include breakpoint(upto-mobile-320){
                top: 8.1rem;
            }
        }
    }
  .whoWe-wrap  .smart-head{
    padding-top: 1rem!important;
        transform: translateY(-46px) !important;
        background: #fff;
        font-size: 1.5em;
        width: 95%; 
        z-index: 1;
        // filter: drop-shadow(2px 4px 6px #999);
        margin: 0 auto;
    }
    .sky-high-invest {
        position: relative;
        padding-bottom: 3rem !important;

        .smart-head {
            transform: translateY(-18px) !important;
            // top: -67px;
            background: #fff;
            font-size: 1.7em;
            width: 100%;
            padding: 1rem 0 0!important;
            margin-bottom: .5rem;
            // filter: drop-shadow(2px 4px 6px #999);
        }
    }

    .sky-form-wrap {
        .chakra-form-control {
            .chakra-stack {
                display: grid;
                grid-template-columns: 70px 1fr;
                grid-gap: .5rem;

                input {
                    width: 100%;
                }
            }

            &:last-child {
                .chakra-stack {
                    display: block;
                }
            }
        }
    }
}

@include breakpoint(upto-landscape-tablet-992) {
    .sidebar-wrapper {
        .product-subcontent  {
            width: 300px;
            transform: translateX(-15px);
            height: 100vh;
        }
        height: 100vh !important;

        hr {
            margin: 0 0 .5rem;
        }

        .top-content {
            a {
                font-size: .875em;
            }

            p {
                margin: 0 !important;
            }

            .sidebar-wrapper {
                height: max-content !important;
                margin-top: 0 !important;
            }
        }
    }
.enquiry-image-form{
    .modal-left-item{
        margin-left: 0;
    }
}
    .enquiry-wrapper {
        .modal-dialog.modal-lg {
            display: flex;
            align-items: center;
            height: calc(100vh - 20px);
        }

        .modal-content-grid {
            flex-wrap: wrap;
        }

        .modal-left-item .desktop-logo {
            margin: 0;

            @include breakpoint(upto-mobile) {
                max-width: 80%;
                margin: 0 auto;
            }
        }

        .modal-left-item,
        .modal-right-item {
            width: 100% !important;
        }

        .modal-right-item {
            margin: 1rem 0 0;

            @include breakpoint(upto-mobile) {
                margin: 0;

                form {
                    label {
                        font-size: .875em;
                    }

                    button {
                        font-size: .875em;
                    }
                }

                @include breakpoint(upto-mobile-mid) {
                    padding: 0.5rem;
                }
            }
        }

        .left-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            div {
                width: auto;
                text-align: center;

                img {
                    max-width: 90%;
                    margin: 0 auto .5rem;

                    @include breakpoint(upto-mobile) {
                        max-width: 26%;
                    }
                }

                h2 {
                    font-size: .875em;
                }
            }
        }
    }

    .bottom-menu-wrapper {

        button {
            h2 {
                margin: 0;
            }

            .whatsapp-wrap {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: #fff;
            }

            @include breakpoint(upto-mobile) {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    .ourproject-wrapper {
        padding: .5rem 0 !important;

        .view-btn {
            display: flex;
            margin: 0 auto;
        }

        .carsoule-btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 4rem auto 0;
            @include breakpoint(upto-mobile){
                margin: 3rem auto 0;
            }
            button {
                width: 2.5em;
                height: 2.5em;

                svg {
                    width: 1.5rem;
                }
            }
        }
    }

    .smartliving-wrapper {
        padding: .5rem 0 !important;

        h2 {
            font-size: 2em;
            @include breakpoint(upto-mobile){
                font-size: 1.65em;
            }
        }

        img {
            max-width: 100%;
            margin: 0 auto;
        }

        .manage-wrap {
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            margin: 0 auto;
            width: 95%;
            padding: 3rem 0 1rem;
            text-align: center;

            img {
                display: flex;
                margin: 0 auto 1.5rem;
            }
        }
    }

    .ponner-wrapper {
        padding: 0 !important;
        margin: 1rem auto !important;
        width: 95% !important;

        .poinner-heading {
            margin: 0 !important;
            justify-content: center !important;

            .slidein-top {
                >div {
                    display: block;
                    text-align: center;
                    position: relative;
                    font-size: 37px;

                    @include breakpoint(upto-mobile-375) {
                        font-size: 30px;
                    }

                    img {
                        display: flex;
                        margin: 0 auto;
                    }

                    .pvt-wrap {
                        top: 10px;
                        bottom: inherit;
                        position: relative;
                    }
                }
            }
        }

        @include breakpoint(upto-mobile) {
            .poinner-grid-wrap {
                flex-wrap: wrap;
                justify-content: center;
                // display: block;

                .poinner-grid-img {
                    width: 100%;

                    img {
                        max-width: 90%;
                        height: 200px;
                        margin: 0 auto;

                        @include breakpoint(upto-mobile-mid) {
                            max-width: 70%;
                            margin: 0 auto;
                            height: 186px;
                        }
                    }
                }

                .poinner-grid-right {
                    padding: .5rem 0 !important;
                    text-align: center;

                    .slidein-top {
                        display: none;
                    }

                    .fadein-wrap {
                        display: flex;
                        margin: 2rem 0 .5rem;
                        height: max-content;
                        flex-wrap: wrap;

                        h2 {
                            text-align: center;
                            width: 100%;
                            font-size: 1.5em;
                        }

                        button {
                            display: block;
                            margin: 0 auto;
                            font-size: 1em;
                        }
                    }

                    .carausel-btn-wrap {
                        width: 100%;
                        position: absolute;
                        bottom: -63px;
                        left: 40%;

                        @include breakpoint(upto-mobile-375) {
                            left: 35%;
                        }

                        button {
                            width: 2.5em;
                            height: 2.5em;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            svg {
                                width: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .brands-wrapper {
        @include breakpoint (upto-tablet) {
            padding: 1rem 0 !important;

            .brands-content-wrap {
                padding: 0 !important;
                width: 95%;
                margin: 0 auto;

                h2 {
                    font-size: 2.5em;
                }

                .brands-img-wrap {
                    img {
                        max-width: 90%;
                        height: 100%;
                        object-fit: cover;
                        margin: 0 auto;
                    }
                }

                @include breakpoint (upto-mobile) {
                    display: block !important;

                    .text-brand {
                        width: 100%;
                        text-align: center;
                        padding: 0 0 1rem !important;

                        h2 {
                            font-size: 2.2em;

                            @include breakpoint(upto-mobile-mid) {
                                font-size: 1.6em;
                            }
                        }
                    }

                    .brand-item {
                        display: none;
                    }

                    .brands-img-wrap {
                        display: block;
                        padding: 1rem 0;

                        img {
                            max-width: 50%;
                            height: 100%;
                            object-fit: cover;
                            margin: 0 auto;
                        }
                    }
                }
            }

            @include breakpoint(upto-mobile) {
                .OURALLIANCES {
                    padding: 0;

                    h2 {
                        font-size: 1.9em;

                        @include breakpoint(upto-mobile-mid) {
                            font-size: 1.5em;
                        }
                    }

                    .alliance-brand-wrap {
                        padding: 1rem 0;
                        width: 95%;
                        margin: 0 auto;

                        .slick-slider {
                            .slick-track {
                                // display: flex;
                                // flex-wrap: wrap;
                                // grid-gap: 1rem;
                                // align-items: center;

                                img {
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .testimonials-wrapper {
        padding: .5rem 0 !important;
        width: 95%;
        margin: 0 auto;

        @include breakpoint(upto-mobile-375) {
            display: block;
        }

        .testimonal-heading {
            display: block;
            padding: .5rem 0;

            >div {
                background: transparent;
                width: 100%;
                text-align: center;
                padding: 0;
                display: block;

                br {
                    display: none !important;
                }

                >p {
                    color: #000;
                    font-size: 1.5em;
                }
            }
        }

        .testimonal-video-wrap {
            position: relative;
            left: 0;

            iframe {
                width: 90%;
                height: 300px;
                margin: 0 auto 1.5rem;

                @include breakpoint (upto-mobile) {
                    height: 200px;
                }
            }
        }

        .testimonal-caption-wrapper {
            width: 95%;
            margin: 2rem 1rem 1rem;

            .testimonal-content-grid {
                grid-gap: 4rem;
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                grid-template-columns: 100%;
                height: max-content;

                @include breakpoint(upto-mobile-mid) {
                    height: 210px;

                    @include breakpoint(upto-mobile-375) {
                        height: auto;
                    }
                }

                .icon-left {
                    width: 28px;
                    height: max-content;
                    left: -2%;
                    top: -20px;

                    @include breakpoint(upto-mobile-mid) {
                        width: 23px;
                        left: 0%;
                    }
                }

                .icon-right {
                    width: 28px;
                    height: max-content;
                    right: -2%;
                    bottom: 20px;

                    @include breakpoint(upto-mobile-mid) {
                        width: 23px;
                    }
                }
            }

            .testimonal-caption-item2 {
                margin: 2rem 0 !important;
            }

            .testimonal-caption-item1,
            .testimonal-caption-item2,
            .testimonal-caption-item3 {
                display: block;
                // transform: translate(23px, 0px);
                height: inherit;
                margin: .5rem 0;
                padding: 0 !important;

                p {
                    text-align: center;
                }

                .testmonal-avatar-wrap {
                    height: initial;
                    padding: 0 !important;
                    margin: 0 auto 0 9rem;
                    align-items: center;

                    @include breakpoint(upto-mobile) {
                        margin: 0 auto 0 6rem;

                        @include breakpoint (upto-mobile-mid) {
                            margin: 0 auto 0 3rem;
                        }
                    }

                    p {
                        text-align: left;
                        word-break: break-all;

                        @include breakpoint(upto-mobile-375) {
                            width: 200px;

                            @include breakpoint(upto-mobile-320) {
                                width: 170px;
                            }
                        }
                    }

                    .avatar-icon {
                        width: 4rem;
                        height: 4rem;

                        svg {
                            width: 79%;
                        }

                        @include breakpoint(upto-mobile) {
                            width: 4rem;
                            height:4rem;

                            svg {
                                width: 60%;
                            }
                        }
                    }

                    .avatar-caption-text {
                        p {
                            margin: 0 0 .5rem;

                            @include breakpoint(upto-mobile) {
                                word-break: break-word;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .about-whowe-wrapper {
        padding: 0 !important;

        @include breakpoint(upto-mobile) {
            >div>div {
                padding-bottom: 0;
            }

            @include breakpoint(upto-mobile) {
                .invest-image {
                    max-width: 90%;
                    margin: 1rem auto 1rem;
                    filter: drop-shadow(2px 4px 6px #999);
                }
            }
        }

        .fadein-2sdelay {
            padding-bottom: .8rem;
        }
    }

    .consulting-wrap {
        .unorder-wrapper {
            width: 95%;
            margin: 0 auto;

            ul {
                margin: 0;
                padding: 0;
                width: 100% !important;
                display: flex;
                flex-wrap: wrap;
                grid-gap: .5rem 1rem;
                justify-content: center;

                li {
                    float: inherit !important;
                    width: max-content;
                }
            }
        }
    }

    .ourexpetise-wrapper {
        padding: 0 !important;

        @include breakpoint(upto-mobile) {
            margin-top: .5rem;

            >div {
                padding: 0;

                >div {
                    padding-bottom: 0;
                }

                .fadein-2sdelay {
                    width: 92%;
                    margin: -3.5rem auto 0;
                }
            }
        }

        .fadein-2sdelay {
            padding: 0 .5rem .8rem !important;

            @include breakpoint(upto-mobile) {
                svg {
                    width: 2rem;
                    height: 2rem;

                    @include breakpoint(upto-mobile-375) {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }
    }

    .project-dev-wrapper {
        .project-dev-grid {
            .slick-slider {
                width: 95%;
                margin: 0 auto;

                .slick-track {
                    display: grid;
                    width: 100% !important;
                    grid-template-columns: repeat(3, 1fr);
                    justify-content: center;
                    text-align: center;

                    @include breakpoint (upto-mobile) {
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 1rem;

                        @include breakpoint(upto-mobile-320) {
                            grid-template-columns: repeat(1, 1fr);
                        }

                        div {
                            h2 {
                                font-size: 1.2em;
                            }

                            button {
                                font-size: 14px;
                            }

                            img {
                                max-width: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    div {
                        width: 100% !important;

                        button {
                            margin: .5rem 0 1rem;
                        }
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .prime-grid-gallery {
        .cmn-card {
            width: 99%;
        }

        @include breakpoint (upto-mobile-375) {

            >div {
                padding: 0;
            }
        }
    }

    .enterprise-dropdown-wrapper,
    .serviced-apartments-dropdown {
        .chakra-accordion__item {
            @include breakpoint(upto-mobile) {
                .chakra-collapse {
                    .chakra-accordion__item {
                        padding: .3rem 1rem;

                        button {
                            width: 100%;
                            margin: 0 auto;
                            font-size: .875em;
                            padding: .5rem 0 0;

                            @include breakpoint(upto-mobile-mid) {
                                font-size: .69em;
                            }
                        }
                    }
                }
            }

            h2 {
                button {
                    width: 90%;
                    margin: 0 auto;
                    padding: .8rem 0;

                    @include breakpoint(upto-mobile-mid) {
                        font-size: 1em;

                        @include breakpoint(upto-mobile-375) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    .serviced-apartments-dropdown {
        @include breakpoint(upto-mobile) {
            padding: 0 !important;

            .chakra-collapse {
                >div {
                    padding: 0;
                }
            }
        }
    }

    .arete-homes-wrapper {
        h2 {
            width: 90%;
            // filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
            background: #fff;
            @include breakpoint(upto-mobile-mid) {
                font-size: 1.6em;
                // margin-bottom: 1.5rem;
            }
        }
    }

    .arete-homes-invest-banner {
        @include breakpoint (upto-mobile) {
            .chakra-text {
                font-size: 2rem;
                left: 1rem;
                top: 70%;

                @include breakpoint(upto-mobile-320) {
                    font-size: 1.6em;
                    left: 12px;
                }
            }
        }
    }

    .arete-grid-btn {
        @include breakpoint(upto-mobile){
            .btn-top{
                margin-top: -1rem;
            }
        }
        >div {
            @include breakpoint(upto-mobile-375) {
                 button {
                    font-size: 12px;
                }
            }
        }

        .home-loan-wrap {
            @include breakpoint(upto-mobile-mid) {
                height: auto;
            }

            .fadein {
                @include breakpoint (upto-mobile-mid) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    @include breakpoint(upto-mobile-375) {
                        grid-template-columns: repeat(1, 1fr);
                        gap: 1rem;
                    }
                }
            }
        }
    }

    .financial-model-wrap {
        width: 95%;
        margin: 0 auto;
        padding: .5rem 0 !important;

        @include breakpoint(upto-mobile-375) {
            text-align: center;
        }

        .financial-model-content {
            @include breakpoint(upto-mobile) {
                display: block;

                div {
                    width: 95%;
                    margin: 0 auto;
                }

                .diveder-finance,
                .discoballmultistep {
                    display: none;
                }
            }
        }
    }

    .terms-wrapper {
        width: 95%;
        margin: 0 auto;
    }

    .about-whowe-wrapper {
        .channel-text {
            width: 95% !important;
            margin: 0 auto;
        }
    }

    .sale-manager-wrap {
        .chakra-link {
            div {
                p {
                    margin: 0;
                }
            }
        }
    }

    .refer-btn-wrap {
        button {
            @include breakpoint(upto-mobile-375) {
                font-size: 13px;
            }
        }
    }

    @include breakpoint(upto-tablet) {
        .green-power-mobile {
            .green-energry {
                height: 100vh;

                video {
                    height: 100vh;
                    min-height: max-content !important;
                    object-fit: cover;
                }

                div {
                    h2 {
                        font-size: 1.5em;
                        line-height: 1.5;
                    }
                }
            }

            .green-PLS-wrapper {
                padding-top: 1rem;
                width: 95%;
                margin: 0 auto;

                @include breakpoint(upto-mobile-375) {
                    width: 100%;
                }

                h2 {
                    font-size: 1.6em;
                    background: #fff;
                    width: 74%;
                    padding: 2rem 0rem !important;
                    position: absolute;
                    top: -21px;
                    left: 62px;
                    margin: 0 auto;
                    // filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));

                    @include breakpoint(upto-mobile) {
                        font-size: 1.5em;
                        padding: 1.5rem 0 !important;
                    }
                }

                .para-line1 {
                    padding-top: 5rem !important;
                    text-align: center;
                    @include breakpoint(upto-mobile-375) {
                        padding-top: 5.5rem;
                    }
                }

                p {
                    font-size: 1.1em;
                    word-break: break-word;
                    text-align: center;
                    @include breakpoint(upto-mobile) {
                        font-size: 1em;
                        padding: .5rem 0 !important;

                        &:last-child {
                            margin-bottom: 2rem;
                        }
                    }
                }
            }

            .green-our-project {
                padding: 0 2rem;
                flex-wrap: wrap;
                height: auto;
                flex-direction: column-reverse;

                .green-project-img {
                    width: 100%;
                    margin: 0 !important;
                    @include breakpoint(upto-mobile-375) { 
                        margin: 5rem 0 0 !important;
                    }
                    img {
                        position: static !important;
                        max-width: 100%;
                        object-fit: cover;
                        @include breakpoint(upto-mobile-375) { 
                            width: 70%;
                        }

                        &:nth-child(1) {
                            transform: translate(309px, 23px);
                            z-index: 0;

                            @include breakpoint(upto-mobile-mid) {
                                transform: translate(200px, 23px);

                                @include breakpoint(upto-mobile-375) {
                                    transform: translate(130px, 23px);
                                }
                            }
                        }

                        &:last-child {
                            z-index: 5;
                        }
                    }
                }

                .green-obj {
                    width: 100%;
                    position: relative;
                    @include breakpoint(upto-mobile-375) {
                        padding: 0 .5rem;
                        .text-wrap{
                            position: absolute;
                            left: 0;
                            top: 66px;
                            margin: 0;
                            width: 100%;
                            text-align: center;
                        }
                    }

                    h2 {
                        font-size: 2em;

                        @include breakpoint(upto-mobile-375) {
                            font-size: 1.6em;
                            padding: 0 0 .3rem;
                        }
                    }

                    p {
                        font-size: 1em;
                        padding-bottom: .3rem;

                        @include breakpoint(upto-mobile-375) {
                            font-size: .875em;
                        }
                    }

                    .discobal-slidein-top1nh {
                        transform: translate(17px, 6px) rotate(266deg) !important;

                        @include breakpoint(upto-mobile-375) {
                            div {
                                height: 1rem;
                                width: 1rem;
                            }
                        }

                        hr {
                            height: 100%;
                            width: 100px !important;
                            transform: translate(1px, -32px) rotate(273deg) !important;
                        }
                    }
                }

                div {
                    position: static !important;
                    left: inherit !important;
                    top: inherit !important;
                }
            }

            .green-onestop {
                padding: 4rem 3rem;

                @include breakpoint(upto-mobile-mid) {
                    padding: 4rem 3rem 0;
                }

                >h2 {
                    font-size: 2.1em;

                    @include breakpoint(upto-mobile) {
                        font-size: 1.8em;
                        width: 70%;

                        @include breakpoint(upto-mobile-375) {
                            font-size: 1.5em;
                        }
                    }
                }

                .onestop-grid-content {
                    width: 75%;
                    margin: 0 auto;

                    .grid-box {
                        display: grid;
                        grid-template-columns: 1fr 55px 1fr;
                        width: 77%;
                        margin: 0 auto;

                        @include breakpoint(upto-mobile-375) {
                            width: 100%;
                            .third-height{
                                height:32.085rem;
                            }
                            .height-cmn{
                                height:7.5rem;
                            }
                            hr {
                                margin: 0; 
                            }
                            .bullet-box {
                                height: .875rem;
                                width: .875rem;
                            }
                        }

                        img {
                            @include breakpoint(upto-mobile) {
                                width: 3rem;
                                height: 4rem;
                            }
                        }
                    }

                    div {

                        h2 {
                            font-size: 1.3em;
                            text-align: center;

                            @include breakpoint(upto-mobile) {
                                font-size: 1.089em;

                                @include breakpoint(upto-mobile-mid) {
                                    font-size: 1em;

                                    @include breakpoint(upto-mobile-375) {
                                        font-size: .875em;
                                    }
                                }
                            }
                        }

                        @include breakpoint(upto-mobile-mid) {
                            ul {
                                margin: 0 0 .5rem .5rem !important;
                                padding: 0;

                                li {
                                    font-size: .875em;
                                }
                            }
                        }
                    }
                }
            }

            .green-getin {
                @include breakpoint(upto-mobile-375) {
                    padding: 1rem 0 2rem;
                }

                >h2 {
                    font-size: 1.7em;

                    @include breakpoint(upto-mobile-mid) {
                        font-size: 1.5em;
                    }

                }

                p {
                    @include breakpoint(upto-mobile-mid) {
                        font-size: 1.1em;
                        width: 70%;
                        margin: 0 auto;

                        @include breakpoint(upto-mobile-375) {
                            width: 65%;
                            font-size: .875em;
                        }
                    }
                }

                form {
                    width: 75%;
                    margin: 0 auto;

                    @include breakpoint(upto-mobile-375) {
                        width: 65%;

                        label {
                            font-size: .875em;
                        }
                    }

                    .getin-form-content {
                        .form-stack {
                           .form-grid {
                            &:nth-child(2){
                                input {
                                    width: 72%;
                                    max-width: 80%;
                                }
                            }
                           }
                        }
                        .form-grid { 
                            div {
                                display: grid;
                                grid-template-columns: 80px 1fr;
                                grid-gap: 0 .5rem;
                                width: 100%;

                                input {
                                    width: 100%;
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}