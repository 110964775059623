@keyframes fadein{
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}
@keyframes Icon-fadein{
  from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
@keyframes slidein-top-2sdelay{
  0% {
    transform: translateY(-21.875rem);
  }
  50% {
    transform: translateY(-21.875rem);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slidein-top-4sdelay{
  0% {
    transform: translateY(-21.875rem);
  }
  75% {
    transform: translateY(-21.875rem);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slidein-top{
  from {

      transform: translateY(-18.75rem);
    }
    to {
      transform: translateY(0px);
    }
}
@keyframes slideintop-2sdelay{
  0% {
      transform: translateY(-18.75rem);
    }
    50% {
      transform: translateY(-18.75rem);
    }
    100% {
      transform: translateY(0px);
    }
}
@keyframes fadein-4sdelay{
  0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
@keyframes fadein-6sdelay{
  0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
@keyframes expand{
  from {
      transform: scale(1,1);
    }
    to {
      box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transform: scale(1.05,1.05);
    }
}
@keyframes image-sliedleft{
  from {
      opacity: 0;
      transform: translateX(-100vw);
    }
    to {
      opacity: 1;
      transform: translateX(0vw);
    }
}
@keyframes image-sliedright{
  from {
      opacity: 0;
      transform: translateX(100vw);
    }
    to {
      opacity: 1;
      transform: translateX(0vw);
    }
}
@keyframes image-slieddown{
  from {
      opacity: 0;
      transform: translateY(-100vw);
    }
    to {
      opacity: 1;
      transform: translateY(0vw);
    }
}
@keyframes image-sliedup{
  from {
      opacity: 0;
      transform: translateY(30vw);
    }
    to {
      opacity: 1;
      transform: translateY(0vw);
    }
}
@keyframes slidein-bottom{
  from {

      transform: translateY(18.75rem);
    }
    to {
      transform: translateY(0px);
    }
}
@keyframes slidein-bottom-2sdelay{
  0% {
    transform: translateY(18.75rem);
  }
  50% {
    transform: translateY(18.75rem);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slidein-bottom-4sdelay{
  0% {
    transform: translateY(18.75rem);
  }
  75% {
    transform: translateY(18.75rem);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes discobal-slidein-top{
  from {

      transform: translateY(-25rem);
    }
    to {
      transform: translateY(0px);
    }
}

@keyframes discoball-slidein-left1{
  from {
    opacity: 0;
      transform: translateX(-18.75rem);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}
@keyframes discoball-slidein-left2{
  0% {
    opacity: 0;
      transform: translateX(-18.75rem);
    }
    50% {
      opacity: 0;
        transform: translateX(-18.75rem);
      }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
}
@keyframes discoball-slidein-left3{
  0% {
    opacity: 0;
      transform: translateX(-18.75rem);
    }
    75% {
      opacity: 0;
        transform: translateX(-18.75rem);
      }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
}
@keyframes discoball-slidein-left4{
  0% {
    opacity: 0;
      transform: translateX(-18.75rem);
    }
    75% {
      opacity: 0;
        transform: translateX(-18.75rem);
      }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
}
@keyframes Arete-Plaza-FloorPlan-Title{
  from {
      opacity: 0;
      transform: translateX(-25rem);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}
@keyframes Arete-Plaza-LocationAdv-Title{
  from {
      transform: translateY(-25rem);
    }
    to {
      transform: translateY(0px);
    }
}
@keyframes Arete-Plaza-FloorPlan-Image{
  from {
      opacity: 0;
      transform: translateX(46.313rem);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}
@keyframes rotate-right{
    from {
      transform: rotate(0deg);
      }
    to {
      transform: rotate(360deg);
      }
}
@keyframes rotate-left{
  from {
    transform: rotate(360deg);
    }
  to {
    transform: rotate(0deg);
    }
}
@keyframes Arete-Plaza-LocationAdv-Image{
  from {
    transform: scale(0.5,0.5);
    }
  to {
    transform: scale(1,1);
    }
}
@keyframes shrink{
  from {
    transform: scale(1.5,1.5); 
    transform: translateY(100px);
    }
  to {
    transform: scale(1,1);
    transform: translateY(0px);
    }
}
