@keyframes fadein{
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}
@keyframes expandcar{
  from {
      transform:scale(0.8,0.8)
    }
    to {
      transform:scale(1,1);
    }
}
@keyframes fadein-2sdelay{
  0% {
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
}
@keyframes fadein-4sdelay{
  0% {
      opacity: 0;
    }
    75%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
}
@keyframes slidein-top{
  from {
      transform: translateY(-25rem);
    }
    to {
      transform: translateY(0px);
    }
}
@keyframes sliderightdisco{
  from {
      transform: translateX(-31.25rem);
    }
    to {
      transform: translateX(0px);
    }
}
@keyframes sliderightdisco-2sdelay{
  0% {
    transform: translateX(-31.25rem);
  }
  50%{
    transform: translateX(-31.25rem);
  }
  100%{
    transform: translateX(0px);
  }
}
@keyframes sliderightdisco-4sdelay{
  0% {
    transform: translateX(-31.25rem);
  }
  50%{
    transform: translateX(-31.25rem);
  }
  100%{
    transform: translateX(0px);
  }
}
@keyframes serv-text-up{
  from {
      transform: translateY(-10rem);
    }
    to {
      transform: translateY(0rem);
    }
}
@keyframes serv-text-down{
  from {
      transform: translateY(10rem);
    }
    to {
      transform: translateY(0rem);
    }
}
@keyframes serv-expand-img{
  from {
      transform:scale(0.7,0.7)
    }
    to {
      transform:scale(1,1);
    }
}
@keyframes fadein{
  0% {
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
}
@keyframes fadein2{
  0% {
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
}
@keyframes fadein3{
  0% {
      opacity: 0;
    }
    75%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
}
@keyframes discoleft1{
  0% {
    transform: translateX(-20rem);
    }
    100%{
      transform: translateX(0rem);
    }
}
@keyframes discoleft2{
  0% {
    transform: translateX(-20rem);
    }
    50%{
      transform: translateX(-20rem);
    }
    100%{
      transform: translateX(0rem);
    }
}
@keyframes discoleft3{
  0% {
    transform: translateX(-20rem);
    }
    75%{
      transform: translateX(-20rem);
    }
    100%{
      transform: translateX(0rem);
    }
}
@keyframes discoup{
  0% {
    transform: translateY(-30rem);
    }
    100%{
      transform: translateY(0rem);
    }
}