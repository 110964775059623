.blog-text-right.reveal.active {
  animation: blog-text-right 1 2s linear;
  transform: translateX(0rem);
}
.blog-image-left.reveal.active {
  animation: blog-image-left 1 2s linear;
  transform: translateX(0rem);
}
.blog-text-right.reveal {
  transform: translateX(6.25rem);
}
.blog-image-left.reveal {
  transform: translateX(-6.25rem);
}
.blog-image-down.reveal.active {
  animation: blog-banner-down 1 2s linear;
  transform: translateY(0rem);
}
.blog-image-down.reveal {
  transform: translateY(-6.25rem);
}
.slideTop.reveal {
  transform: translateY(-100vh);
}
.slideTop.reveal.active {
  animation: slideTop 1 2s ease;
  transform: translateY(0vh);
}
.slideTop2.reveal {
  transform: translateY(-100vh);
}
.slideTop2.reveal.active {
  animation: slideTop2 1 4s ease;
  transform: translateY(0vh);
}
.slideTop3.reveal {
  transform: translateY(-100vh);
}
.slideTop3.reveal.active {
  animation: slideTop3 1 6s ease;
  transform: translateY(0vh);
}
.slideTop4.reveal {
  transform: translateY(-100vh);
}
.slideTop4.reveal.active {
  animation: slideTop3 1 8s ease;
  transform: translateY(0vh);
}
.slideTop5.reveal {
  transform: translateY(-100vh);
}
.slideTop5.reveal.active {
  animation: slideTop3 1 10s ease;
  transform: translateY(0vh);
}

option:checked{
   background-color :#DFBD69 !important;
   color:white;
}
